import axios from "axios";
import store from "@/store";
import env from "@/environment";

const BASE_URL = env.fsiSchedulerAPIBaseURL;
const API_VERSION = env.fsiSchedulerAPIVersion;

class FSISchedulerClient {
	domainDataCheck(domain) {
		let resourceURL = "/fsi-scheduler/domain-data-check";
		let query = {
			domain: domain,
		};
		return this.axios.get(resourceURL, { params: query });
	}

	bulkDomainDataCheck(domains) {
		let resourceURL = "/fsi-scheduler/domain-data-check";
		let payload = {
			domains: domains,
		};
		return this.axios.post(resourceURL, payload);
	}

	restartDomainJob(domain) {
		let resourceURL = `/fsi-scheduler/jobs/${domain}/restart`;
		return this.axios.put(resourceURL, {});
	}

	constructor() {
		this.baseUrl = `${BASE_URL}/${API_VERSION}`;
		this.init();
	}

	/**
	 * Initialize Axios defaults and API Map
	 */
	init() {
		this.setupAxios();
	}

	/**
	 * Setup axios
	 */
	setupAxios() {
		this.axios = axios.create({ baseURL: this.baseUrl });

		this.axios.interceptors.request.use(
			(config) => {
				const token = store.getters.auth.token;

				if (token) {
					config.headers.Authorization = `Bearer ${token}`;
				} else {
					throw new axios.Cancel(`Operation canceled by the user \n 
                                            Reson: no auth token present`);
				}

				return config;
			},
			(error) => {
				console.log(`No auth token present @ ApiProvider`);
				console.log(error);
				Promise.reject(error);
			}
		);
	}
}

const fsischedulerapi = new FSISchedulerClient();
export default fsischedulerapi;
