<template>
	<div class="flex mb-3" v-if="showBackButton">
		<v-button @click="back" class="btn btn-small btn-default"
			><span v-html="back_icon"></span> Back to company list</v-button
		>
	</div>
	<div>
		<div class="text-lg font-bold border-b">Digital Reference Check</div>

		<div v-if="prevDomains.length > 0" class="m-5">
			<div>Previous searches</div>
			<div class="p-5">
				<div class="flex" v-for="domain in prevDomains" :key="domain">
					<div class="text-blue-600 hover:underline cursor-pointer" @click="setDomain(domain)">
						{{ domain }}
					</div>
				</div>
			</div>
		</div>

		<DRCEntryPoint :domain="drcDomain" :key="drcDomain" />
	</div>
</template>

<script>
import Button from "@/components/ui/Button";
import DRCEntryPoint from "@/components/projects/digital-reference-check/DRCEntryPoint";
import { mapGetters } from "vuex";

const back_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
                    </svg>`;

export default {
	created() {},

	mounted() {
		if ("drcDomain" in this.$route.query) {
			this.drcDomain = this.$route.query.drcDomain;
		}
	},

	components: {
		"v-button": Button,
		DRCEntryPoint,
	},

	props: ["teamId"],

	data() {
		return {
			drcDomain: "",
			back_icon,
			prevDomains: [],
			showBackButton: this.$route.query.showBackButton,
		};
	},

	methods: {
		setDomain(drcDomain) {
			this.drcDomain = drcDomain;
		},
		back() {
			this.$router.back();
		},
	},

	watch: {},

	computed: {
		...mapGetters(["activeTeamPermission"]),
	},
};
</script>

<style></style>
