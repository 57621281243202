<template>
	<div>
		<div class="w-12/12">
			<div class="flex flex-col xl:w-8/12 mt-5">
				<div class="flex justify-between">
					<div class="bg-white shadow p-2 flex lg:w-10/12 md:w-8/12 rounded-full my-5">
						<span class="w-auto flex justify-end items-center text-gray-500 p-2">
							<span v-html="calculator_icon"></span>
						</span>
						<input
							class="w-full rounded-full p-2 focus:outline-none focus:ring-2 focus:border-blue-300"
							type="text"
							placeholder="Type company website"
							v-model="drcDomain"
							v-on:keyup.enter="onEnterKey"
							:disabled="runningReferenceCheck" />
						<v-button
							:disabled="drcDomain.length == 0 || runningReferenceCheck"
							:loading="runningReferenceCheck"
							@click="runReferenceCheck"
							class="btn btn-small btn-add-new h-10 px-8 ml-3"
							><span v-if="!runningReferenceCheck">Get score</span></v-button
						>
					</div>
					<div v-if="drcResult" class="my-5 p-2">
						<v-button
							@click="saveReferenceCheckPDF"
							:disabled="false"
							:loading="false"
							class="btn btn-small btn-action-outline h-10 mr-3"
							>Download <span v-html="download_icon" class="ml-3"></span
						></v-button>
					</div>
				</div>

				<div v-if="drcCheckError" class="text-center">
					{{ drcCheckError }}
				</div>

				<div v-if="drcResult" class="mt-5">
					<div class="text-sm font-black text-white text-center bg-gray-800 mx-40 my-5 rounded">
						Foresight SI
					</div>
					<div class="mb-5 text-lg font-bold text-center underline">Digital Reference Check</div>
					<div class="mb-8 text-4xl font-thin text-center">
						{{ drcResultDomain }}
					</div>

					<div class="m-5">
						<div class="mb-10">
							<div
								class="text-3xl font-black capitalize mb-5 text-center"
								v-bind:class="{
									'text-green-700': drcResult.score_marking == 'exceptional',
									'text-green-500': drcResult.score_marking == 'very good',
									'text-green-400': drcResult.score_marking == 'good',
									'text-yellow-500': drcResult.score_marking == 'fair',
									'text-red-600': drcResult.score_marking == 'poor',
								}">
								{{ drcResult.score_marking }}
							</div>

							<div class="mb-5">
								<div class="w-full bg-gray-200 rounded-full h-8 dark:bg-gray-700">
									<div
										class="h-8 rounded-full text-white flex justify-center"
										v-bind:class="{
											'bg-green-700': drcResult.score_marking == 'exceptional',
											'bg-green-500': drcResult.score_marking == 'very good',
											'bg-green-400': drcResult.score_marking == 'good',
											'bg-yellow-500': drcResult.score_marking == 'fair',
											'bg-red-600': drcResult.score_marking == 'poor',
										}"
										v-bind:style="{
											width: Math.max(10, 100 * (drcResult.score / MAX_SCORE)) + '%',
										}">
										<div class="font-black mt-1">
											{{ drcResult.score }}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div v-if="drcResult.negative_facts.length > 0" class="mb-10">
							<div class="text-xl text-center underline">Negative facts</div>
							<div>
								<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
									<div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
										<table class="min-w-full divide-y divide-gray-200">
											<thead class="bg-gray-50">
												<tr>
													<th
														scope="col"
														class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
													<th
														scope="col"
														class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
														Category
													</th>
													<th
														scope="col"
														class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
														Datapoint
													</th>
													<th
														scope="col"
														class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-l">
														Detail
													</th>
												</tr>
											</thead>
											<tbody class="bg-white divide-y divide-gray-200">
												<tr v-for="(result, index) in drcResult.negative_facts" :key="index">
													<td class="px-6 py-1 whitespace-nowrap">
														<div class="text-sm text-gray-900">
															{{ index + 1 }}
														</div>
													</td>
													<td class="px-6 py-1 whitespace-nowrap text-center">
														<div class="text-sm text-gray-900 uppercase">
															{{ result.component_category }}
														</div>
													</td>
													<td class="px-6 py-1 whitespace-nowrap text-center">
														<div class="text-xs text-gray-900">
															{{
																TEXT_COPY_MAP[result.component_category][
																	result.component
																]
															}}
														</div>
													</td>
													<td
														class="px-6 py-1 whitespace-nowrap text-sm font-medium border-l">
														{{ result.detail }}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>

						<div class="mb-10">
							<div class="text-xl text-center underline mb-5">Score breakdown</div>

							<div class="mb-10">
								<div class="text-center">Current score</div>
								<div class="w-full bg-gray-200 rounded-full h-6 dark:bg-gray-700 mb-5">
									<div
										class="h-6 rounded-full text-white flex justify-center bg-gray-500"
										v-bind:style="{
											width:
												Math.max(
													10,
													100 *
														((drcResult.score - drcResult.historical_score) /
															(MAX_SCORE * 0.5))
												) + '%',
										}">
										<div class="font-bold">
											{{ drcResult.score - drcResult.historical_score }}
										</div>
									</div>
								</div>

								<div class="text-center">Historical score</div>
								<div class="w-full bg-gray-200 rounded-full h-6 dark:bg-gray-700 mb-5">
									<div
										class="h-6 rounded-full text-white flex justify-center bg-gray-500"
										v-bind:style="{
											width:
												Math.max(10, 100 * (drcResult.historical_score / (MAX_SCORE * 0.5))) +
												'%',
										}">
										<div class="font-bold">
											{{ drcResult.historical_score }}
										</div>
									</div>
								</div>
							</div>

							<div
								v-for="(results, component_category) in drcResult.components"
								:key="component_category">
								<div class="my-5 border rounded-lg bg-white p-5">
									<div class="text-center uppercase font-bold text-gray-500">
										{{ component_category }}
									</div>
									<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
										<div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
											<table class="min-w-full divide-y divide-gray-200">
												<thead class="bg-gray-50">
													<tr>
														<th
															scope="col"
															class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center w-6/12">
															Datapoint
														</th>
														<th
															scope="col"
															class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-l">
															Score
														</th>
													</tr>
												</thead>
												<tbody class="bg-white divide-y divide-gray-200">
													<tr v-for="(result, category) in results" :key="category">
														<td class="px-6 py-1 whitespace-nowrap text-center">
															<div class="text-xs text-gray-900">
																{{ TEXT_COPY_MAP[component_category][category] }}
															</div>
														</td>
														<td class="px-6 whitespace-nowrap text-sm font-medium border-l">
															<div
																class="w-full bg-gray-200 rounded-full h-5 dark:bg-gray-700 my-3">
																<div
																	class="h-5 rounded-full text-white flex justify-center bg-gray-500"
																	v-bind:style="{
																		width:
																			Math.max(
																				10,
																				100 *
																					(result.score /
																						MAX_COMPONENT_SCORES[
																							component_category
																						][category])
																			) + '%',
																	}">
																	<div class="font-bold">
																		{{ result.score }}
																	</div>
																</div>
															</div>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import fsiprofessorapi from "@/services/api-clients/fsiprofessor";
import fsischedulerapi from "@/services/api-clients/fsischeduler";
import { jsPDF } from "jspdf";
import Button from "@/components/ui/Button";
import moment from "moment";

const loading_spinner = `<svg class="animate-spin h-8 w-8 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>`;
const download_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                            </svg>`;
const calculator_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
					  <path stroke-linecap="round" stroke-linejoin="round" d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
					</svg>`;

const MAX_SCORE = 600;
const MAX_COMPONENT_SCORES = {
	digital: {
		growth: 100,
		maturity: 100,
		"web-services": 50,
	},
	esg: {
		E: 50,
		S: 50,
		G: 50,
	},
	innovation: {
		capacity: 50,
	},
	internationalisation: {
		"phone-regions": 50,
		languages: 50,
		"domain-regions": 50,
	},
};
const TEXT_COPY_MAP = {
	digital: {
		growth: "Growth",
		maturity: "Maturity",
		"web-services": "Web technology",
	},
	esg: {
		E: "Posture on environment",
		S: "Posture on social responsibility",
		G: "Posture on governance",
	},
	innovation: {
		capacity: "Integrated capacity",
	},
	internationalisation: {
		"phone-regions": "Number of phone regions",
		languages: "Number of languages",
		"domain-regions": "Number of websites",
	},
};

export default {
	created() {},

	mounted() {
		if (this.domain) {
			this.drcDomain = this.domain;
			this.runReferenceCheck();
		}
	},

	unmounted() {},

	components: {
		"v-button": Button,
	},

	props: ["teamId", "domain"],

	data() {
		return {
			drcDomain: "",
			drcResultDomain: "",
			drcResult: null,
			drcCheckError: "",
			runningReferenceCheck: false,
			calculator_icon,
			download_icon,
			MAX_SCORE,
			MAX_COMPONENT_SCORES,
			TEXT_COPY_MAP,
		};
	},

	methods: {
		async onEnterKey() {
			this.runReferenceCheck();
		},

		async checkDomainData() {
			if (this.checkedDomainData) {
				return this.checkedDomainData;
			} else {
				let res = await fsischedulerapi.bulkDomainDataCheck([this.drcDomain.trim()]);
				if (res.data.domain_data.length == 1) {
					return res.data.domain_data[0];
				} else {
					return null;
				}
			}
		},

		async runReferenceCheck() {
			this.runningReferenceCheck = true;
			this.drcResult = null;
			this.drcCheckError = null;

			let domain_status = null;
			try {
				domain_status = await this.checkDomainData();
			} catch (e) {
				this.drcCheckError = null;
			}

			if (!domain_status) {
				this.drcCheckError = "Could not find data for the domain";
			} else if (["pending", "collecting"].includes(domain_status["data_status"])) {
				this.drcCheckError =
					"Data is being collected for the domain - this could take up to 2 hours - please check back later";
			} else if (["error"].includes(domain_status["data_status"])) {
				this.drcCheckError = "Error collecting data for the domain - please contact us to help";
			} else {
				let fsi_prof_response = await fsiprofessorapi.getReferenceScore([domain_status.domain]);
				if (fsi_prof_response.data.failed.length == 1) {
					this.drcCheckError = "Error building report for the domain - please contact us to help";
				} else {
					this.drcResult = fsi_prof_response.data.results[0].result;
					this.drcResultDomain = fsi_prof_response.data.results[0].domain;
				}
			}
			this.runningReferenceCheck = false;
		},

		async saveReferenceCheckPDF() {
			if (!this.drcResult) {
				return;
			}

			// Default export is a4 paper, portrait, using millimeters for units
			const doc = new jsPDF();

			let xStart = 20;
			let xInc = 10;
			let yStart = 0;
			let yInc = 10;
			let yIndex = 1;

			++yIndex;
			doc.text("Foresight SI", xStart, yStart + yIndex * yInc);
			++yIndex;
			doc.text("Digital Reference Check", xStart, yStart + yIndex * yInc);

			++yIndex;
			doc.line(xStart, yStart + yIndex * yInc, 180, yStart + yIndex * yInc);

			++yIndex;
			doc.text(`${moment().format("dddd, MMMM Do YYYY")}`, xStart, yStart + yIndex * yInc);

			doc.setFontSize(10);

			yStart = yStart + yIndex * yInc;
			yIndex = 1;
			yInc = 8;

			// ++yIndex;
			doc.text(`Domain`, xStart, yStart + yIndex * yInc);
			doc.text(`${this.drcResultDomain}`, xStart + xInc * 4, yStart + yIndex * yInc);

			++yIndex;
			doc.text(`Overall score`, xStart, yStart + yIndex * yInc);
			doc.text(`${this.drcResult.score}`, xStart + xInc * 4, yStart + yIndex * yInc);

			++yIndex;
			doc.text(`Marking`, xStart, yStart + yIndex * yInc);
			doc.text(`${this.drcResult.score_marking.toUpperCase()}`, xStart + xInc * 4, yStart + yIndex * yInc);

			++yIndex;
			doc.line(xStart, yStart + yIndex * yInc, 180, yStart + yIndex * yInc);

			yStart = yStart + yIndex * yInc;
			yIndex = 1;
			yInc = 6;

			// ++yIndex;
			++yIndex;
			++yIndex;
			doc.setFontSize(14);
			doc.text("Negative facts", xStart, yStart + yIndex * yInc);
			doc.setFontSize(10);

			for (let i = 0; i < this.drcResult.negative_facts.length; i++) {
				++yIndex;
				doc.text(`${i + 1}`, xStart, yStart + yIndex * yInc);
				doc.text(`${this.drcResult.negative_facts[i].detail}`, xStart + xInc * 1, yStart + yIndex * yInc);
			}

			++yIndex;
			++yIndex;
			++yIndex;
			doc.setFontSize(14);
			doc.text("Score breakdown", xStart, yStart + yIndex * yInc);
			doc.setFontSize(10);

			++yIndex;
			doc.text(`Current score`, xStart, yStart + yIndex * yInc);
			doc.text(
				`${this.drcResult.score - this.drcResult.historical_score}`,
				xStart + xInc * 15,
				yStart + yIndex * yInc
			);

			++yIndex;
			doc.text(`Historical score`, xStart, yStart + yIndex * yInc);
			doc.text(`${this.drcResult.historical_score}`, xStart + xInc * 15, yStart + yIndex * yInc);

			++yIndex;
			++yIndex;
			++yIndex;
			doc.setFontSize(14);
			doc.text("Components", xStart, yStart + yIndex * yInc);
			doc.setFontSize(10);

			++yIndex;
			for (const [component_category, results] of Object.entries(this.drcResult.components)) {
				for (const [category, result] of Object.entries(results)) {
					doc.text(`${component_category} ${category}`, xStart, yStart + yIndex * yInc);
					doc.text(`${Math.round(result.score)}`, xStart + xInc * 15, yStart + yIndex * yInc);
					++yIndex;
				}
			}

			doc.setFontSize(8);
			doc.text(
				`Generated by Foresight SI (www.networkpraxis.com) on ${moment().format(
					"dddd, MMMM Do YYYY HH:mm:ss"
				)}`,
				xStart,
				275
			);
			doc.text(`© Copyright Network Praxis (ELNAC Ltd.). All rights reserved.`, xStart, 280);

			doc.save(`FSI-Digital-Reference-Check_${this.drcDomain}_${moment().format("YYYYMMDD")}.pdf`);
		},
	},

	watch: {},

	computed: {},
};
</script>

<style></style>
